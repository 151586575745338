import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Configurator from './views/configurator'
import Order from './views/order'
import './App.scss';
import axios from 'axios';
import {Helmet} from 'react-helmet';
import Loading from 'react-loading';

interface AppState {
	brand: {
		logo: string,
		name: string,
		style: string,
		subdomain: string,
		website: string
		menu: Array<{
			label: string,
			url: string;
		}>
	},
	loading: boolean
}

class App extends React.Component<{}, AppState> {
	
	constructor(props: any) {
		super(props);

		this.state = {
			brand: ({} as any),
			loading: false
		}
	}

	componentDidMount() {
		// var full = window.location.host
		// //window.location.host is subdomain.domain.com
		// var parts = full.split('.')
		// var sub;
		// if (parts.length > 2) {
		// 	sub = parts[0]
		// 	var domain = parts[1]
		// }
		var full = window.location.pathname.split('/');
		var sub = full[1] !== 'design' ? full[1] : 'design'
		this.setState({
			loading: true
		})
		// console.log(sub, domain);
		// if (full.match('localhost') || full.match('ngrok')) {
		// 	sub = 'uds'
		// }
		if (sub && !sub.match('localhost')) {
			axios.get(`${process.env.REACT_APP_API_URL}/api/brand/${sub}`).then((data) => {
				// console.log(data);
				this.setState({
					brand: data.data,
					loading: false
				})
				document.body.classList.add(data.data.style);
				// document.title = data.data.name + ' Mosaic Creator';
			}).catch(() => {
				this.setState({
					loading: false
				})
			})
		}

		setTimeout(() => {
			if (!this.state.brand.name) {
				this.setState({
					loading: false
				})
			}
		}, 4000)
	}

	

	render() { 
		const Page404 = ({ location }: { location: any }) => (
			<div>
			   <h2>No match found for <code>{location.pathname}</code></h2>
			</div>
		 );
		return (
			<div className={`App ${this.state.brand.style}`}>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Custom mosaic tile design from {this.state.brand.name ? this.state.brand.name : ''}</title>
					<meta name="description" content="Come design, play, and create a custom USA handcrafted mosaic tile with our mosaic creator. Choose from different patterns and materials to create the perfect look for your next project."/>
					<link rel="canonical" href={`https://${this.state.brand.subdomain ? `${this.state.brand.subdomain}.` : ''}${process.env.REACT_APP_URL}${window.location.pathname}`} />
				</Helmet>
				{this.state.loading && (
					<div className="loading">
						<Loading type="spin" color="#000"/>
						<p>Loading</p>
					</div>
				)}
				{!this.state.loading && (
					<div>
						{this.state.brand.name ? (
							<div>
								<header>
									<div className="logo">
										<a href={this.state.brand.website}>
											<img src={this.state.brand.logo ? this.state.brand.logo : ''} alt=""/>
										</a>
									</div>
									<div className="menu">

									
									{window.location.href.match('/design/') ? (
										<p>Create your own design at: <a href={`http://${process.env.REACT_APP_URL}/${this.state.brand.subdomain}`}>{`${process.env.REACT_APP_URL}/${this.state.brand.subdomain}`}</a></p>
									): (
										this.state.brand.menu && Array.isArray(this.state.brand.menu) && this.state.brand.menu.length ? this.state.brand.menu.map((item) => {
											return item.url ? <a href={`${item.url}`} className="back">{item.label}</a> : ''
										}) : (
											<a href={`${this.state.brand.website ? this.state.brand.website : 'https://www.uniquebuildingconcepts.com/'}`} className="back">Back to site</a>
										)
									)}
									</div>
								</header>
								<Router>
									<Switch>
										<Route path="/" exact render={props => <Configurator {...props} brand={this.state.brand} />}/>
										<Route path="/:brand" exact render={props => <Configurator {...props} brand={this.state.brand} />}/>
										<Route path="/order/:id" render={props => <Order {...props} brand={this.state.brand} />} />
										<Route path="/design/:id" render={props => <Order {...props} brand={this.state.brand} />} />
										<Route path="/:brand/design/:id" render={props => <Order {...props} brand={this.state.brand} />} />
										<Route component={Page404} />
									</Switch>
								</Router>
							</div>
						) : (
							<div className="no_brand">
								<h1>Coming Soon</h1>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default App;
