import React from "react";
import Modal from 'react-modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactLoading from "react-loading";
import { faPlus, faRandom, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import './materials.scss'
import Select from "react-select";
import { Close } from "./close";
import { float } from "html2canvas/dist/types/css/property-descriptors/float";
const EventBus = require('eventing-bus');

export interface MaterialsProps {
    handleMaterials: any;
    meterialAdded: any;
    changePercent: any;
    materials: any;
    patterns: any
    materialsApi: any;
    handleBlend: any;
    removedMaterial: any;
    randomizedMaterial: any;
    locked: boolean;
    closedModal: any;
}

export interface MaterialsState {
    modalIsOpen: boolean;
    id: string;
    percentage: any;
    loading: boolean;
    sumPercentage: any;
    oldId: string;
    actualZone: number;
}

class Materials extends React.Component<MaterialsProps, MaterialsState> {
    constructor(props: MaterialsProps) {
        super(props);
        this.state = {
            modalIsOpen: false,
            id: '',
            percentage: {},
            loading: false,
            sumPercentage: {},
            oldId: '',
            actualZone: 1
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.radnomizeMaterial = this.radnomizeMaterial.bind(this);
        this.changePercent = this.changePercent.bind(this);
        this.addMaterial = this.addMaterial.bind(this);

    }

    public openModal() {
        this.setState({ modalIsOpen: true, id: 'material-1_1' });
        setTimeout(() => {
            const usedItems = document.querySelectorAll('.items .item.used');
            
            usedItems.forEach((el) => el.classList.add('visited'))
        }, 200);
    }

    public closeModal() {
        if (this.state.modalIsOpen) {
            this.props.closedModal();
            setTimeout(() => {
                if (this.props.patterns['pattern'].randomize) {
                    this.radnomizeMaterial()
                } else {
                    this.radnomizeMaterialInZone()
                }
                this.setState({ modalIsOpen: false });
            }, 200)
        }
    }

    public IsJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public validatePercentage(materials: any) {

        
        let sumPercentageLocal: any = this.state.sumPercentage || {};
        
        if (materials.length) {
            
            materials.forEach((key: any) => {

                sumPercentageLocal[this.props.materials[key].zone] = (sumPercentageLocal[this.props.materials[key].zone] || 0) + parseFloat(this.props.materials[key].percent);
            });
        } else {
            
            for (const key in materials) {
                if (materials.hasOwnProperty(key)) {
                    const element = materials[key];
                    
                    sumPercentageLocal[element.zone] = (sumPercentageLocal[element.zone] || 0) +  parseFloat(element.percent);
                }
            }
            
        }
        this.setState({
            sumPercentage: sumPercentageLocal
        })
        
        return Object.keys(sumPercentageLocal).filter((key: any) => sumPercentageLocal[key] === 100).length;
    }
    public tries = 0;

    public getRandom(allChips: any, materialsChips: any, chip?: any): number {
        const random = Math.floor(Math.random() * allChips.length)
        const randomChip = allChips[random];
        let prevChip;
        let nextChip;
        let maxTries = 30;
        if (this.props.patterns['pattern'].id === 49) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 5) || materialsChips.includes(randomChip - 6);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 5) || materialsChips.includes(randomChip + 6);
        }
        if (this.props.patterns['pattern'].id === 48) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 9) || materialsChips.includes(randomChip - 10) || materialsChips.includes(randomChip - 11);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 9) || materialsChips.includes(randomChip + 10) || materialsChips.includes(randomChip + 11);
        }
        if (this.props.patterns['pattern'].id === 41) {
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 15) || materialsChips.includes(randomChip - 16);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 15) || materialsChips.includes(randomChip + 16);
        }
        if (this.props.patterns['pattern'].id === 77) {
            maxTries = 300;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 8) || materialsChips.includes(randomChip - 9);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 9) || materialsChips.includes(randomChip + 10);
        }
        if (this.props.patterns['pattern'].id === 62) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 10) || materialsChips.includes(randomChip - 9) || materialsChips.includes(randomChip - 11);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 10) || materialsChips.includes(randomChip + 9) || materialsChips.includes(randomChip + 11);
        }
        // if (this.props.patterns['pattern'].id === 66) {
        //     maxTries = 100;
        //     prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 10) || materialsChips.includes(randomChip - 1);
        //     nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 10) || materialsChips.includes(randomChip + 1);
        // }
        if (this.props.patterns['pattern'].id === 67) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 10) || materialsChips.includes(randomChip - 11) || materialsChips.includes(randomChip - 12);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 10) || materialsChips.includes(randomChip + 11) || materialsChips.includes(randomChip + 12);
        }
        if (this.props.patterns['pattern'].id === 73) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 10) || materialsChips.includes(randomChip - 11) || materialsChips.includes(randomChip - 12);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 10) || materialsChips.includes(randomChip + 11) || materialsChips.includes(randomChip + 12);
        }
        if (this.props.patterns['pattern'].id === 80) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 5) || materialsChips.includes(randomChip - 6);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 5) || materialsChips.includes(randomChip + 6);
        }
        // if (this.props.patterns['pattern'].id === 81) {
        //     maxTries = 100;
        //     prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 3) || materialsChips.includes(randomChip - 4);
        //     nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 3) || materialsChips.includes(randomChip + 4);
        // }
        if (this.props.patterns['pattern'].id === 82) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 3);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 3);
        }
        if (this.props.patterns['pattern'].id === 82) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 6);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 6);
        }
        if (this.props.patterns['pattern'].id === 85) {
            maxTries = 300;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 8);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 8);
        }
        if (this.props.patterns['pattern'].id === 70) {
            maxTries = 100;
            prevChip = materialsChips.includes(randomChip - 1) || materialsChips.includes(randomChip - 17) || materialsChips.includes(randomChip - 18) || materialsChips.includes(randomChip - 19);
            nextChip = materialsChips.includes(randomChip + 1) || materialsChips.includes(randomChip + 17) || materialsChips.includes(randomChip + 18) || materialsChips.includes(randomChip + 19);
        }
        
        if (this.tries < maxTries && (prevChip && nextChip)) {
            this.tries = this.tries + 1;

            return this.getRandom(allChips, materialsChips);
        }
        this.tries = 0;
        return randomChip;

    }

    public randomNumber(allChips: any, used: any) {
        let maxTries = 10;
        const random = allChips[Math.floor(Math.random() * allChips.length)]
        if (used.length && used.includes(random) && this.tries < maxTries) {
            this.randomNumber(allChips, used);
        }
        this.tries = 0;
        return random
    }

    public count(arr: any[]) {
        return arr.reduce((prev: any, curr: any) => (prev[curr] = ++prev[curr] || 1, prev), {})
    }

    public radnomizeMaterialInZone(materialKey?: any) {

        setTimeout(() => {
            const materials = this.props.materials;
            const zones = this.count(Object.keys(materials).map((key) => key.replace(new RegExp("_[0-9]{1,2}", "g"), '')));

            Object.keys(zones).forEach((materialKey: any) => {
                const zone = materialKey.replace('material-', '').replace(/_\d*/, '');
                const materialsInZone = Object.keys(materials).filter((key) => key.match(materialKey ? materialKey.replace(new RegExp("_[0-9]{1,2}", "g"), '') : ''));
                this.setState({
                    sumPercentage: {
                        ...this.state.sumPercentage,
                        ...{[zone]: 0}
                    }
                })

                // const chipsLength = document.querySelectorAll(`.singleSVG g[id="zone-${zone}"] *`).length;
                

                if (!this.validatePercentage(materialsInZone)) {
                    const tiles = document.querySelectorAll('.svg__wrapper > div:not(.kitchen-wrapper), .injected-svg svg')
                    tiles.forEach((tile, idx) => {
                        let chips: any;
    
                        if (tile) {
                            chips = tile.querySelectorAll(`g[id="zone-${zone}"] circle, g[id="zone-${zone}"] path, g[id="zone-${zone}"] ellipse, g[id="zone-${zone}"] rect, g[id="zone-${zone}"] polygon`);
                        }
                        chips.forEach((chip: any) => {
                            chip.style.fill = '';
                        });
                    });
                    return false;
                }

                const tiles = document.querySelectorAll('.svg__wrapper > div:not(.kitchen-wrapper), .injected-svg svg')
                const tile = document.querySelector('.svg__wrapper > div')

                // if (materialsInZone.length === 1) {
                //     return false;
                // }
                tiles.forEach((tile, idx) => {
                    // if (idx !== 0) return false; 
                    let chips: any;

                    if (tile) {
                        chips = tile.querySelectorAll(`g[id="zone-${zone}"] circle, g[id="zone-${zone}"] path, g[id="zone-${zone}"] ellipse, g[id="zone-${zone}"] rect, g[id="zone-${zone}"] polygon`);
                    }

                    // chips.forEach((el: Element) => {
                    //     (el as HTMLElement).style.fill = `#ccc`;
                    //     (el as HTMLElement).setAttribute('class', ``);
                    // })

                    const chipsAmount = chips.length;
                    let allChips: any = [];
                    for (let index = 1; index <= chipsAmount; index++) {
                        allChips.push(index)
                    }
                    
                    let usedChips = 0;
                    materialsInZone.forEach((key, idx) => {
                        
                        if (!this.props.materials[key].src) {
                            return false;
                        }
                        const percent = this.props.materials[key].percent
                        
                        let amountOfChips = Math.round((chipsAmount) * (percent) * 0.01)
                        
                        // if (key.match('material-3')) {
                        // }
                        
                        if (amountOfChips > allChips.length) {
                            amountOfChips = allChips.length
                        }
                        usedChips += amountOfChips;
                        if (usedChips < chipsAmount && materialsInZone.length - 1 === idx) {
                            // if (key.match('material-3')) {
                            // }
                            amountOfChips = chipsAmount - usedChips;
                            
                        }
                        
                        const usedId: any = [];

                        for (let i = 0; i < amountOfChips; i++) {

                            const random = this.randomNumber(allChips, usedId)
                            usedId.push(random)
                            let url = this.props.materials[key].src.replace('${process.env.REACT_APP_API_URL}/storage/uploads/', '')
                            let chipsUrl = this.props.materials[key].chips;

                            chipsUrl = this.IsJsonString(chipsUrl) ? JSON.parse(chipsUrl) : chipsUrl;

                            if (chipsUrl && chipsUrl.length) {
                                url = chipsUrl[Math.floor(Math.random() * chipsUrl.length)];
                            }

                            const chipSingle = chips[random - 1] || chips[random];
                            if (chipSingle) {
                                chipSingle.style.fill = `url(#${url.replace('.png', '').replace(``, '')})`;
                                chipSingle.setAttribute('class', key);
                                allChips.splice(allChips.findIndex((item: any) => item === random), 1)
                            }
                        }

                    });


                })
            })
            this.props.randomizedMaterial();
        }, 400)

    }

    public radnomizeMaterial(item?: any, key?: string) {
        
        const tiles = document.querySelectorAll('.svg__wrapper > div:not(.kitchen-wrapper), .injected-svg svg')
        const tile = document.querySelector('.svg__wrapper > div')
        let chips: any = 0;
        if (tile) {
            chips = tile.querySelectorAll('g circle:not(#grout), g rect:not(#grout), g path:not(#grout), g ellipse:not(#grout), g polygon:not(#grout)');
        }
        console.log(tile);
        
        console.log('chips', chips.length);
        
        chips.forEach((el: Element) => {
            (el as HTMLElement).style.fill = `#ccc`;
            (el as HTMLElement).setAttribute('class', ``);
        })
        const chipsAmount = chips.length;

        setTimeout(() => {
            this.setState({
                sumPercentage: {
                    1: 0
                }
            })
            if (!this.validatePercentage(this.props.materials)) {
                return false;
            }
            EventBus.publish("loadingStart")
            tiles.forEach((tile, idx) => {

                if (tile) {
                    chips = tile.querySelectorAll('g circle:not(#grout), g rect:not(#grout), g path:not(#grout), g ellipse:not(#grout), g polygon:not(#grout)');

                    chips.forEach((el: Element) => {
                        (el as HTMLElement).style.fill = `#ccc`;
                        (el as HTMLElement).setAttribute('class', ``);
                    })

                    let materials = this.props.materials || {};
                    const chipsPerMaterials: any[] = [];
                    let allChips: any = [];

                    for (let index = 1; index <= chipsAmount; index++) {
                        allChips.push(index)
                    }
                    
                    // for (let index = 0; index < Object.keys(this.props.materials).length; index++) {
                    Object.keys(this.props.materials).forEach((key, index) => {
                        
                        
                        chipsPerMaterials.push({[key]: Math.ceil((chipsAmount) * (materials[key].percent) * 0.01)})
                    })
                    // }

                    let materialsChips: any = {}
                    for (const key in materials) {
                        if (materials.hasOwnProperty(key)) {
                            materialsChips = {
                                ...materialsChips,
                                [key]: []
                            }
                        }
                    }
                    console.log('allChips.length ', allChips.length );
                    
                    const chunk = allChips.length / 1;

                    let temparray: any[] = [];

                    for (let i = 0; i < allChips.length; i += chunk) {
                        let chips = allChips;
                        let myChunk = chips.slice(i, i + chunk);
                        temparray.push(myChunk);
                    }
                    console.log('chipsPerMaterials', chipsPerMaterials.length);
                    
                    chipsPerMaterials.forEach((mat: any, idx) => {
                        const key = Object.keys(mat)[0]
                        const num = mat[key]
                        for (let index = 0; index < temparray.length; index++) {

                            let part: any[] = temparray[index];
                            for (let index = 0; index < num / 1; index++) {
                                let randomChip: number = this.getRandom(part, materialsChips[key]);

                                part = part.filter((chip: any) => chip !== randomChip)
                                materialsChips[key].push(randomChip);
                            }
                            temparray[index] = part
                        }

                    });

                    Object.keys(materialsChips).forEach((key) => {
                        const arr = materialsChips[key];
                        
                        console.log(tile, arr, materialsChips, key);
                        
                        let chipsEl = tile.querySelectorAll(arr.map((id: number) => {
                            return `#chip-${id}`
                        }).join(', '))

                        let url = materials[key] ? materials[key].src.replace('${process.env.REACT_APP_API_URL}/storage/uploads/', '') : item.src.replace(`materials/${key}/', ''`)

                        chipsEl.forEach((el: Element) => {
                            let chips = materials[key].chips;

                            chips = this.IsJsonString(chips) ? JSON.parse(chips) : chips;


                            if (chips && chips.length) {

                                url = chips[Math.floor(Math.random() * chips.length)];
                            }

                            (el as HTMLElement).style.fill = `url(#${url.replace('.png', '').replace(``, '')})`;
                            (el as HTMLElement).setAttribute('class', key);
                        })
                    })
                }
            })
            EventBus.publish("loadingEnd")
            this.props.randomizedMaterial();
        }, 400)
    }

    public addMaterial(e?: any, index?: number) {
        if (!this.state.modalIsOpen) {
            const materialsInCureentZone = Object.keys(this.props.materials).filter((key) => key.match(`material-${index}`));

            let lastIndex: any = materialsInCureentZone.map((key) => parseInt(key.replace('material-', '').replace(new RegExp("[0-9]{1,2}_", "g"), ''))).sort((a, b) => a - b)            
            
            lastIndex = parseInt(lastIndex[lastIndex.length - 1]);
            this.openModal()
            this.setState({
                id: `material-${materialsInCureentZone ? `${index}_${lastIndex + 1 || 1}` : Object.keys(this.props.materials).length + 1}`,
                actualZone: index || 1
            })

        }
        this.props.meterialAdded(index);
    }

    public changePercent(e: any, material: any, materialKey?: any) {
        if (!e.target.value) {
            material.percent = 0
            this.setState({
                percentage: {
                    ...this.state.percentage,
                    [materialKey]: material.percent
                },
                sumPercentage: {}
            })
        } else {
            material.percent = Object.keys(this.props.materials).length === 1 ? 100 : (e.target.value ? e.target.value : '')
            this.setState({
                percentage: {
                    ...this.state.percentage,
                    [materialKey]: parseInt(material.percent)
                },
                sumPercentage: {}
            })
        }
            this.props.changePercent(material, materialKey)
            if (this.props.patterns['pattern'].randomize) {
                this.radnomizeMaterial()
            } else {
                this.radnomizeMaterialInZone(materialKey);
            }
    }

    public handleRemovePattern(materialKey: any) {
        const material = this.props.materials[materialKey]

        if (!this.state.modalIsOpen) {
            if (this.props.patterns['pattern'].randomize) {
                this.radnomizeMaterial()
            } else {
                this.radnomizeMaterialInZone(material);
            }
        }
        this.setState({
            sumPercentage: {}
        })
        this.props.removedMaterial(material);
    }

    public showMaterialsInput() {
        let materialInputs = [];

        const zones = this.props.patterns['pattern'].randomize ? 1 : this.props.patterns['pattern'].zones;
        // const zones = Object.keys(this.props.materials).length;

        
        
        for (let i = 1; i <= (zones === 0 ? 1 : zones); i++) {

            
            const materialsInZone = Object.keys(this.props.materials).filter((key) => key.match(`material-${i}`));
            const foundMaterialKey = Object.keys(this.props.materials).find((key) => key.match(`material-${i}`));
            const material = this.props.materials[materialsInZone[0]]
            
            materialInputs.push(
                <div key={i} className={`input ${material ? material.type : ''} material-${i}`}>

                        <div className={`zone zone-${i}`}>{i}</div>

                    <div className="materials-wrapper">
                        {materialsInZone.map((materialKey) => {
                            let percent = 0;
                            if (this.props.materials[materialKey]) {
                                percent = parseInt(this.props.materials[materialKey].percent, 10);
                            }
                            const material = this.props.materials[materialKey]
                            return (
                                <div className={`material-select ${materialKey} ${(this.isMaxItems() || this.isMaxItemsForZone(materialsInZone)) && !material.name ? 'blocked' : ''}`}>
                                    <div
                                        className="material"
                                        onClick={() => {this.openModal(); this.setState({ id: materialKey })}}
                                    >
                                        <img src={material && material.chips && material.chips.length ? material.chips[0] : (material.src ? material.src : `/static/images/mask-${material.type.replace('material_', '')}.png`)} alt="" />
                                        {material && material.name ? material.name : `Click to choose materials...`}
                                    </div>
                                    {/* {(!!this.props.patterns['pattern'].randomize) && */}
                                    {(material && material.name) &&
                                        <div className={`percent ${materialKey}`}>
                                            <input type="text" maxLength={2} value={this.props.materials[`${materialKey}`] ? percent : 0} onChange={(e) => this.changePercent(e, material, materialKey)} />
                                        </div>
                                    }
                                    <div
                                        className="add"
                                        onClick={
                                            material && (materialsInZone.length === 1 && this.props.materials[materialKey].name || materialsInZone.length > 1) 
                                                ? () => this.handleRemovePattern(materialKey)
                                                : () => this.setState({ modalIsOpen: true, id: `${materialKey}` })
                                        }
                                    >
                                        {material && (materialsInZone.length === 1 && this.props.materials[materialKey].name || materialsInZone.length > 1)? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faPlus} />}
                                    </div>
                                </div>
                            )
                        })}

                    </div>

                    {!this.props.patterns['pattern'].randomize && (
                            <div onClick={(e) => this.addMaterial(e, i)} className={`add-button ${foundMaterialKey && this.props.materials[foundMaterialKey].name ? '' : 'hidden'} ${this.isMaxItems() ? 'disabled' : ''} ${this.isMaxItemsForZone(materialsInZone) ? 'disabled disabled-chip' : ''}`}><div >Add Material</div></div>

                    )}
                    {!this.props.patterns['pattern'].randomize && (materialsInZone.length >= 1 && material.name) && (
                        <div className="percentages-wrapper">
                            <div className="percentages">
                                <div className="percantage">
                                    {this.state.sumPercentage[i] && (
                                     <span className={`${this.state.sumPercentage[i] !== 100 ? 'error' : ''}`}> {this.state.sumPercentage[i] || 0}  / 100%</span>
                                    )}
                                </div>
                                {this.showPercentInputError(i) && (
                                    <div className="error">
                                        Total must equal 100%.
                                        <b>{(100 - this.state.sumPercentage[i]) > 0 ? ` Add ${100 - this.state.sumPercentage[i]}%` : ` Remove ${Math.abs(100 - this.state.sumPercentage[i])}%`}</b>
                                    </div>
                                )}
                            </div>

                        </div>
                    )}
                </div>
            )
        }

        return materialInputs;
    }

    public getMaterialCategory(key: string) {
        switch (key) {
            case 'glass':
                return 'Glass'
                break;

            case 'natural_stone':
                return 'Natural Stone Polished'
                break;

            case 'natural_stone_tumbled':
                return 'Natural Stone Tumbled'
                break;

            case 'natural_stone_matte':
                return 'Natural Stone Matte'
                break;

            case 'porcelain':
                return 'Porcelain'
                break;

            case 'metal':
                return 'Metal'
                break;

            // case 'house_blends':
            //     return 'Customize a House Blend'
            //     break;

            default:
                break;
        }
    }

    public getMaterial(item: any, key: any) {
        return axios.get(`${process.env.REACT_APP_API_URL}/api/material/${item.id}`).then((data) => {
            let axiosData = data.data;
            item = { ...item, ...axiosData }
            return Promise.resolve(item)
        });
    }

    public handleBlend(selItem: any, key: string) {
        this.setState({
            loading: true
        })
        const materials = JSON.parse(selItem.materials)

        Object.keys(materials).forEach((key) => {
            const elements: any = document.querySelectorAll(`#zone-${key.replace('material-', '')}`)

            const type = JSON.parse(this.props.patterns['pattern'].materials_zones)[`zone-${key.replace('material-', '').replace(/_\d*/, '')}`];
            
            if (elements) {
                elements.forEach((el: Element) => {
                    const chips = el.querySelectorAll('circle, path, ellipse');
                    let url = materials[key].src

                    chips.forEach((elem: Element) => {
                        const chips = typeof materials[key].chips === 'string' ? JSON.parse(materials[key].chips) : materials[key].chips;
                        if (chips && chips.length) {
                            url = chips[Math.floor(Math.random() * chips.length)];
                        }
                        (elem as HTMLElement).style.fill = `url(#${url.replace('.png', '').replace(``, '')})`;
                    })
                })
            }
            materials[key].type = type;
        })
        selItem.materials = JSON.stringify(materials);
        this.setState({
            loading: false,
        })
        this.closeModal();
        this.props.handleBlend(selItem, key, this.state.id);
    }

    public handleMaterial(selItem: any, key: string, used?: boolean, type?: string) {
        let actualZone = 1;
        if (this.state.id) {
            actualZone = parseInt(this.state.id.replace('material-', '').replace(/_\d*/, ''));
            this.setState({
                actualZone
            })
        }

        if (used && this.props.patterns['pattern'].randomize) {
            
            this.props.removedMaterial(selItem);
            const lastKey = Object.keys(this.props.materials);
            const material = lastKey.length - 2 < 2 ? lastKey[0] : lastKey[lastKey.length - 2]
            
            this.setState({
                id: `${material}`
            })
            return false;
        }
        
        const materialsInZone = Object.keys(this.props.materials).filter((key) => {
            return this.props.materials[key].zone == actualZone
        })
        if (used && !this.props.patterns['pattern'].randomize) {
            const material = this.props.materials[materialsInZone.filter((key) => {
                return this.props.materials[key].name === selItem.name
            })[0]]
                    
            this.props.removedMaterial(material);
            
            this.setState({
                id: material.key
            })
            return false;
        }
        
        if (this.isMaxItems() || this.isMaxItemsForZone(materialsInZone)) {
            return false;
        }

        selItem.type = type;
        this.setState({
            // loading: true,
            oldId: this.state.id
        })
        
        this.addMaterial(null, actualZone || 1)
        const materialsInCureentZone = Object.keys(this.props.materials).filter((key) => key.match(`material-${actualZone}`));
        let lastIndex: any = materialsInCureentZone.map((key) => parseInt(key.replace('material-', '').replace(new RegExp("[0-9]{1,2}_", "g"), ''))).sort((a, b) => a - b)            
        lastIndex = parseInt(lastIndex[lastIndex.length - 1]);
        let id =  this.state.id

        const oldZone = parseInt(this.state.oldId.replace('material-', '').replace(/_\d*/, ''));
        if (actualZone === oldZone) {
            if (this.state.oldId) {
                id = this.state.id === `material-${this.state.actualZone}_${lastIndex + 1}` ? `material-${this.state.actualZone}_${lastIndex + 2}` : `material-${this.state.actualZone}_${lastIndex + 1}`;
            }
            if (this.props.materials[this.state.id] && !this.props.materials[this.state.id].name) {
                id = this.state.id
            }
        }

        this.props.handleMaterials(selItem, key, id);
        setTimeout(() => {
            this.setState({
                loading: false,
                id: `material-${this.state.actualZone}_${lastIndex + 1}`
            })
        }, 50)
    }

    public handleMaterialGroup(e: any) {
        (document.getElementById(e.value) as any).scrollIntoView(true);
    }
    
    public removePercent() {
        this.setState({
            percentage: {},
            sumPercentage: [{'1':0}]
        })
    }

    private placeholderInput() {
        return (
            <div className="input">
                <div className="material-select" onClick={() => this.setState({ modalIsOpen: true, id: 'material-1' })}>
                    <div className="add"><FontAwesomeIcon icon={faPlus} /></div>
                    <div className="material">
                        <img src='' alt="" />
                        <div className="">Click to choose materials...</div>
                    </div>
                </div>
            </div>
        )
    }

    private showPercentInputError(zone: any) {

        return !!this.state.sumPercentage[zone] && !(Math.round(this.state.sumPercentage[zone]) === 100)
    }

    private getMaterialType() {
        
        if (this.props.patterns['pattern'] && this.props.patterns['pattern'].randomize && Object.keys(this.props.materials)[0]) {
            return this.props.materials[Object.keys(this.props.materials)[0]].type
        }  

        const materialsForZone = Object.keys(this.props.materials).filter((mat: any) => {
            return this.props.materials[mat].zone == this.state.actualZone
        });
        
        return this.props.materials[this.state.id] 
            ? this.props.materials[this.state.id].type 
            : this.props.materials[materialsForZone[0]] 
                ? this.props.materials[materialsForZone[0]].type
                : ''
    }

    private capitalize(s: string) {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    private getItemUrl(item: any) {

        const chips = JSON.parse(item.chips)[this.getMaterialType().match('material_') ? this.getMaterialType() : this.capitalize(this.getMaterialType())];
        
        if (Array.isArray(chips)) {
            return chips[0];
        }
        return chips
    }

    private isMaxItems() {
        return Object.keys(this.props.materials).filter((key) => this.props.materials[key].name).length === 10
    } 

    private isMaxItemsForZone(materialsInZone: any) {
        
        materialsInZone = Object.keys(this.props.materials).filter((materialKey) => {
            if (materialsInZone.includes(materialKey)) {
                
                return !!this.props.materials[materialKey].name
            }
            return false;
        })
        if (!materialsInZone[0]) {
            return false;
        }
        const zone = materialsInZone[0].replace('material-', '').replace(/_\d*/, '');
        const chipsLength = document.querySelectorAll(`.singleSVG g[id="zone-${zone}"] *`).length;

        return materialsInZone.length === chipsLength
    } 

    private isVisible(key: any) {
        const l = (this.props.materialsApi as any)[`${key}`] && (this.props.materialsApi as any)[`${key}`].map((item: any, idx: number) => {
            if (!item.sizes) {
                return true;
            }
            const materialForZoneType = JSON.parse(item.sizes).map((item: any) => item === 'Leaf' ? item : item.toLowerCase()).includes(this.getMaterialType());
            return materialForZoneType === true;
        }).filter((item: any) => item)
   
        return !!l && l.length
        
    }

    private mouseOut(e: any) {
        e.currentTarget.classList.add('visited')
    }

    private materialsLength() {
        
        const itemsInZone = Object.keys(this.props.materials).filter((key) => {
            return this.props.materials[key].name && 
            this.state.id && 
            key.match(this.state.id.replace(new RegExp("_[0-9]{1,2}", "g"), ''))
        });

        return this.props.patterns['pattern'] && itemsInZone.length ? `(${itemsInZone.length})` : '';
    }

    render() {
        let noMatsForSize: any = {};
        const materialsInZone = Object.keys(this.props.materials).filter((key) => {
            return this.props.materials[key].zone == this.state.actualZone
        })
        return (
            <div className={`step ${this.props.patterns['pattern'] && this.props.patterns['pattern'].randomize ? 'randomize' : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 0 }} width="0" height="0">
                    <defs>
                        <clipPath id="diamond-mask">
                            <path id="material-placeholder-diamond-4" d="M197.179,29.689a68.628,68.628,0,0,1-16.233-12.31A75.6,75.6,0,0,1,171.7,6.245c-.044-.065-.088-.129-.132-.194-.135-.2-.268-.406-.4-.609q-.24-.368-.47-.734c-.065-.1-.13-.206-.193-.309-.7-1.124-1.338-2.237-1.907-3.333a2.169,2.169,0,0,0-3.735,0A68.641,68.641,0,0,1,152.552,17.3a68.169,68.169,0,0,1-16.527,12.456,2.206,2.206,0,0,0-.008,3.365,68.152,68.152,0,0,1,16.535,12.459,68.517,68.517,0,0,1,12.337,16.3l.029.054a2.191,2.191,0,0,0,3.373.484A67.654,67.654,0,0,1,180.9,45.582,67.683,67.683,0,0,1,197.742,32.97a2.19,2.19,0,0,0-.562-3.281" transform="translate(-135.243)" fill="#333" />
                        </clipPath>
                        <clipPath id="diamond-mask-small">
                            <path id="material-placeholder-diamond-4" d="M164.736,14.137a32.68,32.68,0,0,1-7.73-5.862,36,36,0,0,1-4.405-5.3l-.063-.093c-.065-.1-.128-.193-.19-.29q-.114-.175-.224-.35l-.092-.147c-.332-.535-.637-1.065-.908-1.587a1.033,1.033,0,0,0-1.779,0,32.686,32.686,0,0,1-5.86,7.732,32.461,32.461,0,0,1-7.87,5.931,1.051,1.051,0,0,0,0,1.6,32.454,32.454,0,0,1,7.874,5.933,32.627,32.627,0,0,1,5.875,7.761l.014.025a1.043,1.043,0,0,0,1.606.23,32.216,32.216,0,0,1,6.006-8.017A32.23,32.23,0,0,1,165,15.7a1.043,1.043,0,0,0-.268-1.563" transform="translate(-135.243)" fill="#333" />
                        </clipPath>
                        <clipPath id="leaf-mask">
                            <path id="chip-1" d="M.011,115.7C0,115.5,0,115.294,0,115.077a1.076,1.076,0,0,1,.141-.509l.056-.228c1.005-3.685,6.877-5.91,39.528-25.685C57,78.195,69.923,65.3,92.764,61.387a64.54,64.54,0,0,1,12.962-1.18l.775,0h0l.773,0a64.591,64.591,0,0,1,12.964,1.179c22.557,3.865,35.445,16.491,52.4,26.88l.638.389c33.326,20.184,38.754,22.085,39.583,25.916a1.051,1.051,0,0,1,.142.505l0,.318h0l-.008.319c.008.2.011.406.011.623a1.074,1.074,0,0,1-.139.506l-.056.228c-.994,3.649-6.751,5.862-38.538,25.088l-.991.6c-17.272,10.461-30.2,23.355-53.039,27.268a64.54,64.54,0,0,1-12.962,1.18l-.775,0h0l-.773,0a64.591,64.591,0,0,1-12.964-1.179c-22.557-3.865-35.445-16.491-52.4-26.88L36.8,140.992C6.086,122.464.948,120.561.142,116.846A1.062,1.062,0,0,1,0,116.337l0-.318H0Z" transform="translate(0 -60.207)"/>
                        </clipPath>
                    </defs>
                </svg>
                <div className="heading">
                    <div className="index">02</div>
                    <div className="name">Materials</div>
                </div>
                <div className={`inputs-group ${this.showPercentInputError(1) ? 'error' : ''} materials-${Object.keys(this.props.materials).filter((key) => this.props.materials[key].name).length}`}>

                    {this.props.patterns['pattern'] ? (
                        this.showMaterialsInput()
                    ) : (
                            this.placeholderInput()
                        )}
                    {this.props.patterns['pattern'] && (!!this.props.patterns['pattern'].randomize) && !!Object.keys(this.props.materials).filter((key) => this.props.materials[key].name).length && (
                        <div className="percentages-wrapper">
                            <div className="percentages">
                                <div className="percantage">
                                    {this.state.sumPercentage[1] && (
                                    <span className={`${this.state.sumPercentage[1] !== 100 ? 'error' : ''}`}> {this.state.sumPercentage[1]}  / 100%</span>
                                    )}
                                </div>
                                {this.showPercentInputError(1) && (
                                    <div className="error">
                                        Total must equal 100%.
                                        <b>{(100 - this.state.sumPercentage[1]) > 0 ? ` Add ${100 - this.state.sumPercentage[1]}%` : ` Remove ${Math.abs(100 - this.state.sumPercentage[1])}%`}</b></div>
                                )}
                            </div>
                            <div className="buttons-randomize">
                                <div onClick={(e) => this.addMaterial(e, 1)} className={`add-button ${Object.keys(this.props.materials).length === 10 ? 'disabled' : ''}`}><div >Add Material</div></div>
                            </div>
                        </div>
                    )}
                </div>
                
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={() => this.closeModal()}
                    contentLabel="Example Modal"
                    ariaHideApp={false}
                    portalClassName={`materials-modal ${this.isMaxItems() || this.isMaxItemsForZone(materialsInZone) ? 'max-length' : ''}`}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            width: '80vw',
                            marginRight: '0',
                            marginTop: '0',
                            transform: 'translate(-50%, -50%)',
                            overflow: 'hidden',
                            padding: '0 0px',
                            maxHeight: '90vh'
                        }
                    }}
                >

                    <div className="modal__materials_header">
                        <h2>Select Materials</h2>
                        <div className="links">
                            <Select
                                onChange={this.handleMaterialGroup}
                                options={Object.keys(this.props.materialsApi).map((key: any, idx: any) => { return { value: key, label: this.getMaterialCategory(key) } })}
                                placeholder="Jump to..."
                                className="select-grout"
                                classNamePrefix="select-grout"
                            />
                        </div>
                        {Object.keys(this.props.materials).length > 0 ? (
                            // <div className="apply_materials" onClick={() => this.closeModal()}>Apply <span>materials {this.props.patterns['pattern'] && this.props.patterns['pattern'].randomize && Object.keys(this.props.materials).filter((key) => this.props.materials[key].name).length ? `(${Object.keys(this.props.materials).filter((key) => this.props.materials[key].name).length})` : ''}</span></div>
                        <div className="apply_materials" onClick={() => this.closeModal()}>Apply <span>materials {this.materialsLength()}</span></div>
                        ) : (
                                <div className="close__button" onClick={() => this.closeModal()}>
                                    <Close />
                                </div>
                            )}
                        {this.isMaxItems() && (
                            <div className="alert">You have reached the maximum of 10 materials for your design.</div>
                        )}
                        {this.isMaxItemsForZone(materialsInZone) && (
                            <div className="alert">You have reached the maximum of materials for zone</div>
                        )}
                    </div>
                    <div className={`modal__materials_content ${this.getMaterialType()}`}>
                        <div className="content">
                            
                            <p className="note">Note: Due to material thickness, this design can include both Glass & Natural Stone OR just Porcelain.</p>
                            {Object.keys(this.props.materialsApi).map((key, idx) => {
                                const usedCateogries = Object.keys(this.props.materials).map((key) => this.props.materials[key].category)
                                const materialZones = this.props.patterns['pattern'] ? JSON.parse(this.props.patterns['pattern'].materials_zones) : [];
                                const materialZonesTypes = Object.keys(materialZones).map((item: any, idx: any) => materialZones[item])
                                const blockedPorcelainClass = `${key === 'porcelain' && (
                                    usedCateogries.includes('glass') || usedCateogries.includes('natural_stone') || usedCateogries.includes('natural_stone_tumbled') || usedCateogries.includes('natural_stone_matte') || usedCateogries.includes('metal')
                                    // (this.props.patterns['pattern'] && this.props.patterns['pattern'].specification && JSON.parse(this.props.patterns['pattern'].specification).thickness.match('3/8'))
                                ) ? 'blocked' : ''}`
                                const blockedGlassOrStoneClass = `${(key === 'glass' || key === 'natural_stone' || key === 'natural_stone_tumbled' || key === 'natural_stone_matte' || key === 'metal') && (usedCateogries.includes('porcelain')) ? 'blocked' : ''}`
                                const blockedTypePorcelain = key === 'porcelain' && (materialZonesTypes.includes('oval') || materialZonesTypes.includes('diamond')) ? 'blocked-type' : '';
                                const noHouseBlend = (this.props.materialsApi as any)[`${key}`] && (this.props.materialsApi as any)[`${key}`].every((material: any) => material.blend === 0) ? 'hidden' : ''
                                const materialsInZone = Object.keys(this.props.materials).filter((key) => this.state.id && key.match(this.state.id.replace(new RegExp("_[0-9]{1,2}", "g"), '')));
                                noMatsForSize[key] = false;
                                return (
                                    <div key={idx} id={key} className={`type ${blockedPorcelainClass} ${blockedGlassOrStoneClass} ${blockedTypePorcelain} ${noHouseBlend} ${this.isVisible(key) ? '' : 'hidden'}`}>
                                        <h4>
                                        
                                            {this.getMaterialCategory(key)} {blockedPorcelainClass && (<span>Porcelain not available to mix with Glass or Natural Stone.</span>)}
                                        </h4>
                                        <div className="items">
                                            {(this.props.materialsApi as any)[`${key}`] && (this.props.materialsApi as any)[`${key}`].map((item: any, idx: number) => {
                                                const usedMaterials = materialsInZone.filter((key) => { return true }).map((key) => this.props.materials[key].name);
                                                if (key === 'house_blends') {
                                                    return (
                                                        <div className={`item`} key={idx} onClick={() => this.handleBlend(item, key)}>

                                                            {!item.design_image ?
                                                                <img src='' alt="" />
                                                                :
                                                                <img key={item.design_image} src={`${item.design_image ? item.design_image : ''}`} alt="" />
                                                            }
                                                            <p>{item.blend_name ? item.blend_name : item.pattern.name}</p>
                                                        </div>
                                                    )
                                                } else {
                                                    const materialForZoneType = JSON.parse(item.sizes).map((item: any) => item === 'Leaf' ? item : item.toLowerCase()).includes(this.getMaterialType());
                                                    
                                                    return (
                                                        <div className={`item ${materialForZoneType ? '' : 'hidden-full'} ${usedMaterials.includes(item.name) ? 'used' : ''}`} key={idx} onMouseLeave={(e) => this.mouseOut(e)} onClick={() => this.handleMaterial(item, key, usedMaterials.includes(item.name), this.getMaterialType())}>
                                                            <img src={`${item.chips !== "[]" ? this.getItemUrl(item) : item.src}`} alt="" />
                                                            <p>{item.name}</p>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    </div>
                                )
                            }
                            )}


                            {this.state.loading && (
                                <div className="loading">
                                    <ReactLoading color="#000" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="close__footer">
                        <div className="apply_materials tick" onClick={() => this.closeModal()}>
                            Apply <span>materials {this.materialsLength()}</span></div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Materials;